import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiMetrodeparis } from "react-icons/si";

import 'bootstrap/dist/css/bootstrap.min.css';

import {useContext, useEffect, useState} from 'react';
//import './App.css';
import {UserContext, MenuPathContext} from './context'
import {BrowserRouter, Route, Routes, useNavigate, Link} from 'react-router-dom';
import {Button, Container, Navbar, Nav, Row, Col} from 'react-bootstrap';
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify';

//'ResourcesConfig | LegacyConfig | AmplifyOutputs
import '@aws-amplify/ui-react/styles.css';
import {awsconfig, environment, recaptchaKey} from './config';
import {MenuProps} from './types'
//import {MenuProps, DmarcData, DmarcDataRecord} from './types'
//import {MenuProps } from './types'
import {RoutingValue, RoutingValueProps} from './types'
import {ContentFsm} from './const'
import {footerOptions, unauthOptions, authOptions, menuButtonCancel, menuButtonSignin, menuButtonSignout, revalidateRoutes, unauthRoutes, authRoutes} from './menusAndRoutes'

import { useLocation, useParams } from 'react-router-dom';

import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

//Amplify.configure(awsconfig);


console.log('+++++++++++++++++awsconfig', JSON.stringify(awsconfig, null, 2));


const MenuWrap = () => {
  const { authStatus} = useAuthenticator(context => [context.authStatus]);
  const { userStatus, setUserStatus } = useContext(UserContext);
  const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext)

  const navigate = useNavigate();

  let location = useLocation();

  useEffect(() => {
    console.debug('MenuWrap useEffect authStatus:', authStatus, window.location, lastMenuPath, location.search);
    setUserStatus((authStatus === 'authenticated') ? ContentFsm.SIGNED_IN : ContentFsm.SIGNED_OUT);
    navigate(lastMenuPath);
  }, [authStatus]);

  useEffect(() => {
    console.debug('MenuWrap useEffect userStatus:', userStatus, window.location, location.search);
    if (userStatus === ContentFsm.REVALIDATE) {
      setUserStatus((authStatus === 'authenticated') ? ContentFsm.SIGNED_IN : ContentFsm.SIGNED_OUT);
      console.debug('MenuWrap useEffect lastMenuPath:', lastMenuPath);
      navigate(lastMenuPath);
    }
  }, [userStatus]);


  console.debug('MenuWrap userStatus:', userStatus, 'authStatus:', authStatus, 'lastMenuPath:', lastMenuPath);

  var output;

  switch (userStatus) {
      case ContentFsm.REVALIDATE:       output = null;                                                          break;

      case ContentFsm.SIGNED_OUT:
      case ContentFsm.UNDEFINED:        output = (<Menu options={unauthOptions}/>);                             break;
      
      //case ContentFsm.SIGNED_OUT:       output = (<Menu options={unauthOptions} button={menuButtonSignin}/>);   break; // this version adds a signin button
    

      case ContentFsm.SIGNING_OUT:      output = (<Menu options={authOptions} button={menuButtonCancel}/>);     break;

      case ContentFsm.SIGNING_IN:       output = (<Menu options={unauthOptions} button={menuButtonCancel}/>);   break;

      case ContentFsm.SIGNED_IN:        output = (<Menu options={authOptions} button={menuButtonSignout}/>);    break;

      default:                          output = (<div><p>Unknown State {userStatus}</p></div>);
  }

  return ( 
    <>

      {output}

    </>
  );
}






const MenuBottom = (props:MenuProps) => {
  const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext);
 
  const navigate = useNavigate();


  const menuSelect = (key:any) => {
    navigate(key);
    setLastMenuPath(key);
  };


  //const JSXmenu   = props.options.map(option => <Nav.Link as={Link} to={option.path}  key={option.title}>{option.title}</Nav.Link>)
  //const JSXmenu   = props.options.map(option => <Nav.Link eventKey={option.path}  onClick={(selectedKey) => menuSelect(selectedKey)} href={option.path}  key={option.title}>{option.title}</Nav.Link>)
  const JSXmenu   = props.options.map(option => <Nav.Link eventKey={option.path}  onClick={(e) => e.preventDefault()} href={option.path}  key={option.title}>{option.title}</Nav.Link>)
// <Nav className="mr-auto menu-bar menu-bottom" onSelect={(selectedKey) => menuSelect(selectedKey)}>
                 
  return (
        <Col>
          <Navbar collapseOnSelect  expand="lg" sticky="top" className="menu_row menu-bottom" >  
              <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
              <Navbar.Collapse id="reponsive-navbar-nav" key="reponsive-navbar-nav"> 
                  <Nav className="mr-auto menu-bar menu-bottom" onSelect={(selectedKey) => menuSelect(selectedKey)}>
                    { JSXmenu }
                  </Nav>
              </Navbar.Collapse>     
          </Navbar>
        </Col>
  )
}

const Menu = (props:MenuProps) => {
    const { userStatus, setUserStatus } = useContext(UserContext); 
    const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext);
   
    const navigate = useNavigate();

    const menuSelect = (key:any) => {
      //window.location.replace(key); 
      navigate(key);
      setLastMenuPath(key);
    };

    const buttonSelect = (params:any) => {
      const [key, newState] = params.split(',');
      console.debug('Menu buttonselect key:', key, 'newState:' ,newState);
      navigate(key);
      setUserStatus(newState)
    };

    //as={Link} to={props.to}>
    // const JSXmenu   = props.options.map(option => <Nav.Link eventKey={option.path} key={option.title}>{option.title}</Nav.Link>)
   
    //const JSXmenu   = props.options.map(option => <Nav.Link as={Link} to={option.path} key={option.title}>{option.title}</Nav.Link>)
    const JSXmenu   = props.options.map(option => <Nav.Link eventKey={option.path}  onClick={(e) => e.preventDefault()} href={option.path}  key={option.title}>{option.title}</Nav.Link>)
    const JSXbutton = (props.button === undefined) ? <div className="logopadding"></div> : <Nav.Link eventKey={`${props.button.path},${props.button!.state}`}><Button variant={props.button.variant}>{props.button.title}</Button></Nav.Link>


    return (
          <Col>
            <Navbar collapseOnSelect  expand="lg" sticky="top" className="menu_row header" >
                <SiMetrodeparis className="logoSymbol" />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="order-md-0 order-0  burgerleft burgercolor" />
                <Navbar.Collapse id="reponsive-navbar-nav" key="reponsive-navbar-nav" className=""> 
                    <Nav className="mr-auto  menu-bar" onSelect={(selectedKey) => menuSelect(selectedKey)}>
                        { JSXmenu }
                    </Nav>
                </Navbar.Collapse>
                

                <Nav className="mr" onSelect={(selectedKey) => buttonSelect(selectedKey)}>
                     { JSXbutton }
                </Nav>
        
            </Navbar>
          </Col>
    )
}



const RoutingWrap = (myprops:any) => {
  const { userStatus, setUserStatus } = useContext(UserContext);

  console.debug('RoutingWrap userStatus:',userStatus);

  const mapRoutes = (item:RoutingValue) => {


    
    //const  itemCopy:RoutingValue = {...item}
    //delete item.component;
    const {component, ...props} = item;
    //:RoutingValue = {...item}
    
    


    //element={<item.component props={(item.props!==undefined) ? {...item.props, path: item.path} : {path: item.path}}/>}
              
    return (
            <Route path={item.path} 
                   element={<item.component {...props}/>}
                   key={item.component}/>
    )
  }

  var output;

  switch (userStatus) {
   
      case ContentFsm.SIGNING_IN:
      case ContentFsm.SIGNED_OUT:       output = unauthRoutes.map(item => mapRoutes(item)); 
                                        break;  

      case ContentFsm.SIGNING_OUT:     
      case ContentFsm.SIGNED_IN:        output = authRoutes.map(item => mapRoutes(item));   
                                        break;

      case ContentFsm.REVALIDATE:         
      case ContentFsm.UNDEFINED:        
      default:                          output = revalidateRoutes.map(item => mapRoutes(item));
                                        break;
  }
    
  return ( 
      <Routes>  
        {output}
      </Routes>  
  );
};

const Footer = () => {
  const output = (<MenuBottom options={footerOptions}/>);
  return (     
  
      <div className={"center footer"}>
            {output}
      <div>Copyright © 2024 Commercial Reality Ltd. All rights reserved.&nbsp;&nbsp;&nbsp;<span className="version">{environment}</span></div>
         {/*
         <a href="https://www.linkedin.com/company/metricaition/"><FaLinkedin className="linkedIn" /></a>
         <a href="https://x.com/Metricaition"><FaXTwitter className="  twitter" /></a>
        */}
      </div>   
    );      
}


const Header = () => {
  return ( <MenuWrap />);      // displays menus and signin/out button, menus based on context
}

const Content = () => {
  return ( <RoutingWrap /> );   // provides routes for navigation, routes based on context
}
//return ( <Col className="min-vh-100"> <RoutingWrap /> </Col> );   // provides routes for navigation, routes based on context

const Routing = () => {
  const [lastMenuPath, setLastMenuPath] = useState<string>(`${window.location.pathname}${window.location.search}`);



  console.log('ROUTING', useParams(), window.location, lastMenuPath );



  return (
    <MenuPathContext.Provider value={{lastMenuPath, setLastMenuPath}}>
    <BrowserRouter>
    <Container className="d-flex flex-column container" fluid="lg">             
      <Row>
        <Col><Header /></Col>
      </Row>
      <Row className="flex-grow-1">
        <Col><Content /></Col>
      </Row>
      <Row>
        <Col><Footer /></Col>
      </Row>
    </Container>
  </BrowserRouter>
  </MenuPathContext.Provider> 
  )
}


export const Layout = () => {
  const [userStatus, setUserStatus] = useState<string>(ContentFsm.UNDEFINED);
  //const [lastMenuPath, setLastMenuPath] = useState<string>(window.location.pathname);




  useEffect(() => {

    console.log('LAYOUT', window.location);
  
  }, []);

  return (
    <Authenticator.Provider>
      <UserContext.Provider value={{userStatus, setUserStatus}}>
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>

          <Routing />

        </GoogleReCaptchaProvider>
      </UserContext.Provider>
    </Authenticator.Provider>

  )
}

