import { fetchAuthSession } from 'aws-amplify/auth';
import { get, post } from 'aws-amplify/api';
import { apiName } from './config';


const defaultHeaders = {
    "Access-Control-Allow-Headers":     "*",
    "Access-Control-Allow-Methods":     "*",
    "Access-Control-Allow-Origin":      "*",
}


const  ApiGet = async (path: string, auth:boolean=true) => {
    try {
    
        var response:any = {};

        const headers:any = await ApiHeaders(auth);
 
        const restOperation =  get({apiName: apiName, path: path, options:{headers: headers}}); 
        const { body } = await restOperation.response;
        response = await body.json();

        console.debug('ApiGet', JSON.stringify(response, null, 2));
    
        return response;    
    }
    catch (e) {
        console.debug('Exit Catch Error', JSON.stringify(e, null, 2));
        return {'error': JSON.stringify(e, null, 2)};
    }
}


const  ApiPost = async (path: string, inputBody:string, auth:boolean=true) => {

    try {
    
        var response:any = {};

        const headers:any = await ApiHeaders(auth);
 
        const restOperation =  post({apiName: apiName, path: path, options:{body: inputBody, headers: headers}}); 
        const { body } = await restOperation.response;
        response = await body.json();

        console.debug('ApiPost', JSON.stringify(response, null, 2));
    
        return response;    
    }
    catch (e) {
        console.debug('Exit Catch Error', JSON.stringify(e, null, 2));
        return {'error': JSON.stringify(e, null, 2)};
    }
}


const ApiHeaders = async (auth:boolean) => {
    
    const headers:any = {...defaultHeaders};
    if (auth) {
        headers.Authorization = `Bearer ${(await fetchAuthSession())?.tokens?.idToken?.toString()}`;
    } 
    return headers;
}


export {ApiGet, ApiPost};
  