import React, { useCallback } from 'react';
import { host, env  } from './config'

import {useRef, useContext, useEffect, useState} from 'react';

import { Button, Col, Form, Row, InputGroup, Container, Tab, Tabs } from 'react-bootstrap';
import { ApiPost } from './Api'
import { LoadingFsm } from './const'

import { UrlImage } from './UrlImage'

import { PagesModal } from './Pages'

//import * as formik from 'formik';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';

//import ReCAPTCHA   from "react-google-recaptcha";

import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from 'react-router-dom';

//const recaptchaRef = React.createRef();
//const recaptchaRef = useRef


//export const EntryForm = (props:any) => {

export const EntryForm = (props: {setRequest:any, chargeable:boolean}) => {
  //const key =  process.env.REACT_APP_RECAPTCHA_KEY!

  //console.log('key', key);
  return (
    <EntryFormChild {...props}/>
  )
  /*return (
    <GoogleReCaptchaProvider reCaptchaKey={key}>

        <EntryFormChild {...props}/>

   </GoogleReCaptchaProvider>
   )*/
}

export const EntryFormChild = (props: {setRequest:any, chargeable:boolean}) => {
  const [url, setUrl] = useState('');
  const [urlVerified, setUrlVerified] = useState(false);
  const [token, setToken] = useState('');
  const [terms, setTerms] = useState(false);
  //const [values, setValues] = useState({});
  
  interface formValues {
    url:        string,
    name:       string,
    email:      string,
    terms:      boolean,
    optIn:      boolean,
    coupon:     string,
    couponName: string,
    recaptcha:  string
  }

  const [initialValues, setInitialValues] = useState({
    url:          'https://',
    name:         '',
    email:        '',
    terms:        false,
    optIn:        false,
    coupon:       '',
    couponName:   '',
    recaptcha:    ''
  });

//////////////

  const { executeRecaptcha } = useGoogleReCaptcha();

/////////////

  const location = useLocation();

  const handleReCaptchaVerify = useCallback(async (values:formValues) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    values.recaptcha =  await executeRecaptcha('submit');
    

    props.setRequest(values);

    //setToken(await executeRecaptcha('yourAction'));
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  //console.log('ENTRY FORM SEARCH', JSON.stringify(parameters, null, 2));
  
  useEffect(() => {
    var parameters = Object.fromEntries(new URLSearchParams(decodeURIComponent(location.search)));

    setInitialValues({
      url:           (parameters.hasOwnProperty('url'))        ?   parameters.url :   'https://',
      name:          (parameters.hasOwnProperty('name'))       ?   parameters.name:   '',
      email:         (parameters.hasOwnProperty('email'))      ?   parameters.email:  '',
      terms:        ((parameters.hasOwnProperty('terms'))     &&  (parameters.terms==='true')) ? true : false,
      optIn:        ((parameters.hasOwnProperty('optIn'))     &&  (parameters.optIn==='true')) ? true : false,
      coupon:        (parameters.hasOwnProperty('coupon'))     ?   parameters.coupon :      '',
      couponName:    (parameters.hasOwnProperty('couponName')) ?   parameters.couponName 
                                                               :  (parameters.hasOwnProperty('coupon')) ? parameters.coupon : '',
      recaptcha: ''
    });
    parameters.hasOwnProperty('url') && checkUrl(parameters.url);
    parameters = {};
  }, [props]);

  //const reCaptchaRef = React.createRef();
  //const reCaptchaRef = useRef(null);
  //const { Formik } = formik;
  //const { values, submitForm } = useFormikContext();
/*
    useEffect(() => {


      console.log('Values Chane', values);

  }, [values]);
*/
 /*THIS IS CAUSEING ERRORS SO DISABLING FOR V1
  check our https://medium.com/@vivekjoon1234/enhancing-security-and-user-experience-a-guide-to-implementing-google-recaptcha-v3-in-react-js-884b21bcbb79
 and https://stackoverflow.com/questions/53832882/react-and-recaptcha-v3
 and https://codesandbox.io/p/devbox/nextjs-google-recaptcha-v3-demo-803er0?file=%2Fpages%2Findex.js
 
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
    //const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
    

      const token = await executeRecaptcha('yourAction');
      // Do whatever you want with the token
      
      setToken(token);

      console.log('recaptcha token', token);

     //await getRecaptchaScore(token, "");


    }, [executeRecaptcha]);



    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
      
      return () => {
        // cancel the subscription
        setToken('');
      };

    }, [handleReCaptchaVerify]);

*/

  const checkUrl = (value:string) => {
    const host = value.trim();
    if (host.match(hostName)) {
      //const domain = value.match(domainName);
      //setUrl((domain === null) ? '' : domain[0]);
      setUrl(host);
    } else {
      setUrl('');
    }
  }


  const domainName  = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}/
  const hostName    = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/gm
  //const hostName = /^https?:\/\/[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

  //const number = /^[0-9]{1,3}$/gm

  //const reCaptchaRef = React.createRef();

 

/*
  const initialValuesXX:formValues = {
    url:     (parameters.hasOwnProperty('url'))   ?   parameters.url : 'https://',
    name:    (parameters.hasOwnProperty('name'))  ?   parameters.name:  '',
    email:   (parameters.hasOwnProperty('email')) ?   parameters.email: '',
    terms:   ((parameters.hasOwnProperty('terms')) && (parameters.terms==='true')) ? true : false,
    optIn:   ((parameters.hasOwnProperty('optIn')) && (parameters.optIn==='true')) ? true : false,
    //simple: '',
    //specialised: '',
    //free: '',
    //tab: 'simple',
    recaptcha: "",
  }
  */
 // if (parameters.hasOwnProperty('url'))  { console.log('HAS URL'); checkUrl(parameters.url); parameters = {} }


  const schemaChargeable= yup.object().shape({
    tab: yup.string(),

    url:       yup.string().required('Please Enter your candidate Url').matches(hostName,'Name must be a valid URL name (e.g. https://example.com/landing)'),
    email:     yup.string().email('Enter Email valid format (e.g. John@example.com)').required('Please Enter your Email (e.g. John@example.com)'),
    terms:     yup.boolean().required().oneOf([true],'Terms & Conditions must be accepted to proceed'),
  
   //   recaptcha: yup.string().required()
    //simple:       yup.string().when('tab', { is: 'simple', then(schema) { return schema.required() } }),
    //specialised:  yup.string().when('tab', { is: 'specialised', then(schema) { return schema.required() } }),
    //free:         yup.string().when('tab', { is: 'free', then(schema) { return schema.required().matches(number,'Name must be a number <= 999')} }),
    //free:         yup.string().required().matches(hostName,'Entry must be a number'),

  });

  const schemaNonChargeable= yup.object().shape({
    tab: yup.string(),

    url:       yup.string().required('Please Enter your candidate Url').matches(hostName,'Name must be a valid URL name (e.g. https://example.com/landing)'),
    name:      yup.string().min(1,'Your Name must be at least 1 character long').required('Please Enter your Name (e.g. Jane Smith)'),
    email:     yup.string().email('Enter Email valid format (e.g. John@example.com)').required('Please Enter your Email (e.g. John@example.com)'),
    terms:     yup.boolean().required().oneOf([true],'Terms & Conditions must be accepted to proceed'),
  
   //   recaptcha: yup.string().required()
    //simple:       yup.string().when('tab', { is: 'simple', then(schema) { return schema.required() } }),
    //specialised:  yup.string().when('tab', { is: 'specialised', then(schema) { return schema.required() } }),
    //free:         yup.string().when('tab', { is: 'free', then(schema) { return schema.required().matches(number,'Name must be a number <= 999')} }),
    //free:         yup.string().required().matches(hostName,'Entry must be a number'),
    //recaptcha: "",
  });
 
  console.debug('EntryForm', url, urlVerified)
  
  return (
 <>
       <Row>
           
        <Col sm={12} md={8}>

          <Formik
            validationSchema={(props.chargeable) ? schemaChargeable : schemaNonChargeable}
            validateOnBlur={false} 
            validateOnChange={false} 
       
            //validateOnMount={false}
            //onSubmit={console.log}
      
            initialValues={initialValues}
            enableReinitialize 

            //onSubmit={(values:any) => {props.parameters.thread.urls = [values.url]; props.setRequest(props.parameters)}}  
            
            onSubmit={(values:any) => {
              // assume all parameters are verfied need only to check the image is verified and add captcha
              if (urlVerified) {
                handleReCaptchaVerify(values);
                //props.setRequest(values);
                //setValues(values);
              }
            }} 
          
            //onSubmit={handleReCaptchaVerify}
            //onSubmit={(values:any) => {defaultParameters.thread.urls = [values.url]; props.setRequest(defaultParameters)}}  

        
            //onSubmit={(values:any) => ( console.log('post', await ApiPost("openai", defaultData)) )}
            >

            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors, isValid, isValidating, isSubmitting }) => (       
              <Form noValidate onSubmit={handleSubmit} >


              <Row>
                <Form.Group as={Col} controlId="url">   
                  <Form.Label>URL to Analyse:</Form.Label>
                    <InputGroup>      
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="url"
                        value= {values.url}
                        //onChange={handleChange}
                        onChange={(e) => { handleChange(e); checkUrl(e.target.value) }}
                        isInvalid={!!errors.url}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url?.toString()}
                      </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
              </Row>
              <br />
              
              { (props.chargeable) ? "" :
                <>
                <Row>
                  <Form.Group as={Col} controlId="name">   
                      <Form.Label>Your Name:</Form.Label>
                        <InputGroup>      
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="name"
                            value= {values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name?.toString()}
                          </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <br />
                </>
              }
              
       
              <Row>
                <Form.Group as={Col} controlId="email">   
                    <Form.Label>Your Email:</Form.Label>
                      <InputGroup>      
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="email"
                          value= {values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email?.toString()}
                        </Form.Control.Feedback>
                      </InputGroup>
                  </Form.Group>
                </Row>
                <br />
            
              { (values.couponName==='') ? "" :
                  <>
                  <Row>
                  <Form.Group as={Col} controlId="couponName">   
                    <Form.Label>Coupon:</Form.Label>
                      <InputGroup>      
                        <Form.Control
                          type="text"
                          disabled
                          placeholder=""
                          name="couponName"
                          value= {values.couponName}
                          //onChange={handleChange}
                          onChange={handleChange}
                          isInvalid={!!errors.couponName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.url?.toString()}
                        </Form.Control.Feedback>
                      </InputGroup>
                  </Form.Group>
                </Row>
                </>
              }
              <br />
                <Row>
                  <Form.Group as={Col} controlId="optIn"> 
                    <Form.Label>Opt In to receiving news & offers from us:</Form.Label>
                    <InputGroup>     
                      <Form.Check>
                        <Form.Check.Input 
                          type="checkbox"   
                          name="optIn"   
                          checked = {values.optIn}
                          onChange={handleChange}  
                          isInvalid={!!errors.optIn}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.optIn?.toString()}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </InputGroup>              
                  </Form.Group>
                </Row>
                <br />
                
                <Row>
                  <Form.Group as={Col} controlId="terms"> 
                    <Form.Label>Accept Terms & Conditions:</Form.Label>
                    &nbsp;<a className={"link"} onClick={() => setTerms(true)}>click to view terms</a>
                    <InputGroup>     
                      <Form.Check>
                        <Form.Check.Input 
                          type="checkbox"   
                          name="terms"   
                          checked = {values.terms}  
                          onChange={handleChange}  
                          isInvalid={!!errors.terms}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.terms?.toString()}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </InputGroup>              
                  </Form.Group>
                </Row>
                <br />
  
   
                <Button type="submit" className="submit_button">Get Your Report NOW</Button>
        
                {(terms) && <PagesModal url={'/terms'} setHidden={() => setTerms(false)}/>}
  
                <br />
              </Form>
            )}
          </Formik>
          <br />
        </Col>
        <Col>
            {(url.length) > 0 && <UrlImage domainOk={setUrlVerified} title={'Visual Check of URL'} url={url}/>}
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
        
      </>

  );
}
// <Button className="form_button" onClick={() => reCaptchaRef!.current!.execute()}>Test</Button>
/*


           <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey="" //        
                onChange={(value) => {console.log('recaptcha', value); setFieldValue("recaptcha", value)}}
                //size="invisible"
              /> 


              */