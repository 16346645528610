

import React from 'react';

import { Divider } from '@aws-amplify/ui-react';
import {useContext, useEffect, useState, useRef} from 'react';
import { ApiGet } from './Api'
import { useLocation } from 'react-router-dom';

import { LoadingFsm } from './const'

import { Modal, Container, Row, Col, Nav, Button, Image, Accordion, Card, CardGroup, ListGroup, Spinner, Alert} from 'react-bootstrap';

import DOMPurify from 'dompurify';

import { Helmet, HelmetProvider} from 'react-helmet-async';

import { host, siteTitle } from './config'

import {RoutingValueProps} from './types'

///const mySafeHTML = DOMPurify.sanitize(myHTML);

const MINIMUM_FILE_LENGTH = 100; // fiddle index.html is loaded if file cannot be found so screen it out based on file length

export const PagesMeta = (props: any) => {




    const title = `${siteTitle} - ${props.path.replace('/','')}`;
    const url  = `${host}${props.path}`;

    console.debug('PagesMeta path:', props.path, 'title:', title, 'url:', url);

    return (
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <link rel="canonical" href={url} />
          </Helmet>
        </HelmetProvider>
    )
}

export const Pages = (props: RoutingValueProps) => {

    const [fsm, setFsm ]  = useState(LoadingFsm.UNDEFINED);
    const [html, setHtml] = useState("");
    const [path, setPath] = useState("");
    
    //const myRef = useRef(null);

    const location = useLocation();


    // setup any accordions
    useEffect(() => {
      const titleClick = (e:any) => {

        const target     = e.target;               // class === expanded or contracted

        const parent     = target.parentNode;      // class === title
        const grandParent = parent.parentNode;     // class === accordion
  
        // only change recognised classes
        const peers = [...parent.getElementsByClassName("expanded"), ...parent.getElementsByClassName("contracted")];
        for (let i=0; i<peers.length; i++) {
          peers[i].classList.toggle("hidden");
        }
        const content = grandParent.getElementsByClassName("content")
        for (let i=0; i<content.length; i++) {
          content[i].classList.toggle("hidden");
        }
      }
    
      // setup event listeners
      var expander = document.getElementsByClassName("accordion");  
      for (let eItem = 0; eItem < expander.length; eItem++) {
        const title = expander[eItem].getElementsByClassName("title");
        for (let tItem = 0; tItem < title.length; tItem++) {
          title[tItem].addEventListener("click", titleClick);
        }
      }


      return () => {
        // tear down event listeners
        var expander = document.getElementsByClassName("accordion");  
        for (let eItem = 0; eItem < expander.length; eItem++) {
          const title = expander[eItem].getElementsByClassName("title");
          for (let tItem = 0; tItem < title.length; tItem++) {
            title[tItem].removeEventListener("click", titleClick);
          }
        }
      }
      
    }, [fsm]);

    // new page (props changed)
    useEffect(() => {
        //document.title = "Metricaition Analysis";

        setFsm(LoadingFsm.UNDEFINED);

    }, [props]);


    // state change
    useEffect(() => {

        const fetchData = async () => {

          const propsCopy:RoutingValueProps = {...props};


          var propsPath:string = "";
          propsPath = (propsCopy.hasOwnProperty('overidePath')) ? propsCopy.overidePath! : propsCopy.path;

          console.debug('PATHS', propsCopy, propsPath, location.pathname);

          propsPath = (propsPath === '*') ? location.pathname : propsPath;
          
          //console.debug('PATHS', propsPath, location.pathname);

          
          try {

          
              const response = await fetch(`/pages${propsPath}.html`);

              
              if (!response.ok) {
                  throw new Error(`Response status: ${response.status}`);
              }


              console.log('response',response );

              const raw = await response.text();

              if (raw.length <= MINIMUM_FILE_LENGTH) {
                  console.debug('FILE NOT FOUND raw');
                  throw new Error(`File Not Found`);
              }


              const sanitized = DOMPurify.sanitize(raw);


              //setHtml(await response.text());

            
              //console.log('response html',raw.length, sanitized.length, raw);
              
              if (sanitized.length <= MINIMUM_FILE_LENGTH) {
                  console.debug('FILE NOT FOUND sanitised');
                  throw new Error(`File Not Found`);
              }


              setPath(propsPath);
              setHtml(sanitized);
              setFsm(LoadingFsm.FOUND);

          //} catch (error:any)  {
          //    console.error(error.message);
          } catch {
              setHtml(`<h1>Error "/pages${propsPath}.html"  Not Found</h1>`);
              setFsm(LoadingFsm.ERROR);
          }

        };
          
        if (fsm === LoadingFsm.UNDEFINED) {
            setFsm(LoadingFsm.INITIALISING);
            fetchData();

        };
  
      }, [fsm]);

    console.log('fsm', fsm, props);

      //const test:any[] = [      <h1>test</h1>, <p>hello</p>, ]

      //const test:any[] =  [html]

    switch (fsm) {
        case LoadingFsm.FOUND:
        case LoadingFsm.ERROR:
            return (
                <>
                  <PagesMeta path={path}/>
                   <div dangerouslySetInnerHTML={{ __html: html }} />   
                </>
            )
        default:
            return null;    
    }


}


// displays page content in a modal

export const PagesModal = (props:{url: string, title?: string, setHidden: any}) => {

    const handleClose = () => { props.setHidden()};  
    
    const pagesProps:any = {overidePath: props.url}


    return (
      <>
        <Modal show={true} onHide={handleClose}>
          <Modal.Header closeButton>
            {props.hasOwnProperty('title') && props.title}
          </Modal.Header>

          <Modal.Body><Pages path={props.url} /></Modal.Body>
         
        </Modal>
      </>
    );
  }
  