import {Amplify, ResourcesConfig} from 'aws-amplify';

const clientVersion = '1v1'

// reads the .env.local/dev/stage/prod file and creates the config for the environment

// this is used by the Amplify Library
export const awsconfig:ResourcesConfig =   {
    Auth: {
        Cognito: {
            userPoolClientId: process.env.REACT_APP_USERPOOLCLIENTID!,
            userPoolId: process.env.REACT_APP_USERPOOLID!,
            /*loginWith: { // Optional
                oauth: {
                domain: 'auth.dev.metricaition.com',
                scopes: ['openid email phone profile aws.cognito.signin.user.admin '],
                redirectSignIn: ['http://localhost:3000/'],
                redirectSignOut: ['http://localhost:3000/'],
                responseType: 'token',
                },
                //username: false,
                email: true, // Optional
                //phone: false, // Optional
            }*/
        }
    },
    API: {
        REST: {
            [process.env.REACT_APP_APINAME!]: {
                region: process.env.REACT_APP_REGION!,
                endpoint: process.env.REACT_APP_ENDPOINT!
            }
        }
    }
};

Amplify.configure(awsconfig);
console.debug('awsconfig', awsconfig);



export const apiName:string         = process.env.REACT_APP_APINAME!;

export const siteTitle:string       = process.env.REACT_APP_SITE_TITLE!;
export const env:string             = process.env.REACT_APP_ENV!;
export const environment:string     = process.env.REACT_APP_ENVIRONMENT!+' '+clientVersion;

export const logginglevel:string    = process.env.REACT_APP_LOGGING_LEVEL!;
export const recaptchakey:string    = process.env.REACT_APP_RECAPTCHA_KEY!;

export const host:string            = process.env.REACT_APP_HOST!;

export const isProd:boolean         = (env === "prod");

export const recaptchaKey           = process.env.REACT_APP_RECAPTCHA_KEY!;