import {useContext} from 'react';
import {signOut} from 'aws-amplify/auth';
import {Nav, Button, Modal} from 'react-bootstrap';
import {ContentFsm} from './const';
import {UserContext, MenuPathContext} from './context'


export const SignOut = () => {
    const { userStatus, setUserStatus } = useContext(UserContext);
    const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext);

  

    const no = () => { 
        setUserStatus(ContentFsm.REVALIDATE);
    }

    const yes = async () => { 
        //Auth.signOut(); 
        await signOut();
        setLastMenuPath("");
        setUserStatus(ContentFsm.REVALIDATE);
    }

    return (
    <>

        <Modal    
            show={true} 
            onHide={no}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="sm"
        >
        <Modal.Header className="text-center"
            closeButton
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
        <Modal.Title className="text-center">Sign Out</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">Confirm Sign Out</Modal.Body>
        
        <Modal.Footer 
            style={{
            display: "flex",
            justifyContent: "center",
            }}
        >

            <Nav.Link onClick={no}><Button variant="secondary">No</Button></Nav.Link>
            <Nav.Link onClick={yes}><Button variant="primary">Yes</Button></Nav.Link>



        </Modal.Footer>
        </Modal>

    </>
    );
}
